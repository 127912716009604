<template>
  <div class="ExaminationSituation">
    <div class="Examtit clearfix">
      <h6><i class="iconfont icon-ksqk"></i>考试情况</h6>
      <p>共{{dataDetail.total_num}}题，主观题{{dataDetail.essay}}道，答对<span>{{dataDetail.great}}</span>题，答错{{dataDetail.errornum}}题，正确率{{dataDetail.accuracy}}%</p>
    </div>
    <ul class="ExaminationSituationoul">
      <li class="clearfix" v-for="(item,index) in dataDetail.sectioninfo" :key="index">
        <h6>{{item.name}}</h6>
        <p>共{{item.essay_num+item.select_total_num}}题，主观题{{item.essay_num }}道，答对<span>{{item.select_right_num}}</span>题，答错{{item.select_total_num-item.select_right_num}}题，正确率{{item.accuracy}}%</p>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "ExaminationSituation",
  props:{
    dataDetail:{
      type:Object
    }
  }
};
</script>

<style scoped lang="scss"></style>
