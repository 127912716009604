var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"answerWrap"},[_c('div',{staticClass:"answerBtn",on:{"click":_vm.upOrDown}},[_vm._m(0),_vm._v(" 答题卡 "),(!_vm.answerShow)?_c('i',{staticClass:"el-icon-arrow-up rightIcon"}):_c('i',{staticClass:"el-icon-arrow-down rightIcon"})]),(_vm.answerShow)?_c('div',{staticClass:"answerList"},[_vm._m(1),(_vm.type)?_c('div',{staticClass:"ansQue"},_vm._l((_vm.answerTotal),function(ite,inde){return _c('div',{key:inde},[(ite.timu.length > 0)?_c('h2',{staticClass:"fontSize16"},[_vm._v(_vm._s(ite.title))]):_vm._e(),_vm._l((ite.timu),function(item,index){return _c('div',{key:index,staticStyle:{"display":"inline"}},[(!item.list)?[_c('em',{on:{"click":function($event){return _vm.jump(item.question_seq)}}},[_vm._v(" "+_vm._s(item.question_seq)+" "),_c('span',{staticClass:"iconfont",class:{
                  'icon-right right': item.right == 0,
                  'icon-wrong wrong': item.right == 1,
                  'icon-lunshu lunshu': item.right == 3,
                }})]),((index + 1) % 5 == 0 && (index + 1) % 15 != 0)?_c('i'):_vm._e()]:_vm._e(),(item.list)?_vm._l((item.list),function(item1,index1){return _c('strong',{key:index1,staticStyle:{"font-weight":"normal"}},[_c('em',{on:{"click":function($event){return _vm.jump(item1.question_seq)}}},[_vm._v(" "+_vm._s(item1.question_seq)+" "),_c('span',{staticClass:"iconfont",class:{
                    'icon-right right': item1.right == 0,
                    'icon-wrong wrong': item1.right == 1,
                    'icon-lunshu lunshu': item1.right == 3,
                  }})]),((index1 + 1) % 5 == 0 && (index1 + 1) % 15 != 0)?_c('i'):_vm._e()])}):_vm._e()],2)})],2)}),0):_c('div',{staticClass:"ansQue"},_vm._l((_vm.answerTotal),function(item,index){return _c('span',{key:index},[(!item.list)?[_c('em',{on:{"click":function($event){return _vm.jump(item.question_seq)}}},[_vm._v(" "+_vm._s(item.question_seq)+" "),_c('span',{staticClass:"iconfont",class:{
                'icon-right right': item.right == 0,
                'icon-wrong wrong': item.right == 1,
                'icon-lunshu lunshu': item.right == 3,
              }})]),((index + 1) % 5 == 0 && (index + 1) % 15 != 0)?_c('i'):_vm._e()]:_vm._e(),(item.list)?_vm._l((item.list),function(item1,index1){return _c('strong',{key:index1,staticStyle:{"font-weight":"normal"}},[_c('em',{on:{"click":function($event){return _vm.jump(item1.question_seq)}}},[_vm._v(" "+_vm._s(item1.question_seq)+" "),_c('span',{staticClass:"iconfont",class:{
                  'icon-right right': item1.right == 0,
                  'icon-wrong wrong': item1.right == 1,
                  'icon-lunshu lunshu': item1.right == 3,
                }})]),((index1 + 1) % 5 == 0 && (index1 + 1) % 15 != 0)?_c('i'):_vm._e()])}):_vm._e()],2)}),0)]):_vm._e()])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('i',{staticClass:"leftIcon"},[_c('img',{attrs:{"src":require("../../assets/images/icon_dp.png"),"alt":""}})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ul',{staticClass:"answerListoul"},[_c('li',[_vm._v("正确")]),_c('li',[_vm._v("错误")]),_c('li',[_vm._v("论述题")]),_c('li',[_vm._v("未答")])])}]

export { render, staticRenderFns }