<template>
  <div class="ExaminationSituation">
    <div class="Examtit clearfix">
      <h6><i class="iconfont icon-sjbg"></i>数据报告</h6>
    </div>
    <div class="DataReportdiv clearfix">
      <div class="DataReportdivfl fl" :class="{ on: pointinfoShow }">
        <div class="DataReportdivtit">考点分析</div>
        <ul class="DataReportdivoul">
          <li
            class="clearfix"
            v-for="(item, index) in dataDetail.pointinfo"
            :key="index"
          >
            <h6>{{ item.name }}</h6>
            <p>
              共{{ item.essay_num + item.select_total_num }}题，主观题{{
                item.essay_num
              }}道，答对<span>{{ item.select_right_num }}</span
              >题，答错{{
                item.select_total_num - item.select_right_num
              }}题，正确率{{ item.accuracy }}%
            </p>
          </li>
        </ul>
      </div>
      <div class="DataReportdivfr fr" v-if="!pointinfoShow">
        <GradeCurve ref="GradeCurve" />
      </div>
    </div>
  </div>
</template>

<script>
import GradeCurve from "./GradeCurve";
export default {
  name: "DataReport",
  components: {
    GradeCurve,
  },
  data() {
    return {
      dataDetail: "",
      pointinfoShow: "",
    };
  },
};
</script>

<style scoped lang="scss"></style>
