<template>
  <div class="MyScore">
    <div class="MyScorec">
      <h6 class="MyScoretit">
        <img src="../assets/images/zg_cbg6.png" alt="" /><span>我的成绩</span
        ><img src="../assets/images/zg_cbg6.png" alt="" />
      </h6>
      <ul class="MyScorecoul clearfix">
        <li class="clearfix">
          <div>
            <img src="../assets/images/zg_cbg1.png" alt="" />
          </div>
          <dl>
            <dt>{{dataDetail.score_type==1?dataDetail.max_score:dataDetail.max_accuracy}}<span>{{dataDetail.score_type==1?'分':'%'}}</span></dt>
            <dd>最高</dd>
          </dl>
        </li>
        <li class="clearfix">
          <div>
            <img src="../assets/images/zg_cbg2.png" alt="" />
          </div>
          <dl>
            <dt>{{dataDetail.score_type==1?dataDetail.avg_score:dataDetail.avg_accuracy}}<span>{{dataDetail.score_type==1?'分':'%'}}</span></dt>
            <dd>平均</dd>
          </dl>
        </li>
        <li class="clearfix">
          <div>
            <img src="../assets/images/zg_cbg3.png" alt="" />
          </div>
          <dl>
            <dt v-if="dataDetail.score_type==1">{{dataDetail.type==1?dataDetail.answer_score:'未点评'}}<span>/{{dataDetail.total_score}}分</span></dt>
            <dt v-else>{{dataDetail.accuracy}}<span>%</span></dt>
            <dd>我的</dd>
          </dl>
        </li>
        <li class="clearfix">
          <div>
            <img src="../assets/images/zg_cbg4.png"  />
          </div>
          <dl>
            <dt>{{dataDetail.durtime}}</dt>
            <dd>用时</dd>
          </dl>
        </li>
        <li class="clearfix">
          <div>
            <img src="../assets/images/zg_cbg5.png"  />
          </div>
          <dl>
            <dt>{{dataDetail.rank}}<span>%</span></dt>
            <dd>击败考生</dd>
          </dl>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: "MyScore",
  props:{
    dataDetail:Object
  }
};
</script>

<style scoped lang="scss"></style>
