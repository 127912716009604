<template>
  <div>
    <h4 class="fontSize16 lineH20 mb20">成绩曲线</h4>
    <p class="fontSize16 color666 lineH20 mb10">
      <span class="fr">
        较上次练习{{ dataDetailnum >= 0 ? "提升" : "下降" }}
        <em class="fontStyle colorRed">{{ Math.abs(dataDetailnum) }}</em>
        {{ dataDetail.score_type == 1 ? "分" : "%" }}
      </span>
      最近5次考试成绩
    </p>
    <div id="GradeCurve" :style="{ width: '100%', height: '226px' }"></div>
  </div>
</template>

<script>
export default {
  name: "GradeCurve",
  data() {
    return {
      dataDetailnumarr: [],
      dataDetailnum: 0,
      dataDetail:{}
    };
  },
  watch: {
    dataDetail: {
      handler() {
        this.drawCurve();
        this.$nextTick(() => {
          if (this.dataDetailnumarr.length >= 2) {
            this.dataDetailnum =
              this.dataDetailnumarr[this.dataDetailnumarr.length - 1] -
              this.dataDetailnumarr[this.dataDetailnumarr.length - 2];
          } else {
            this.dataDetailnum = 0;
          }
        });
      },
      deep: true
    },
  },
  methods: {
    drawCurve() {
      // 初始化echarts实例
      let myChart = this.$echarts.init(document.getElementById("GradeCurve"));
      // 绘制图表
      this.dataDetailnumarr =
        this.dataDetail.score_type == 1
          ? this.dataDetail.score
          : this.dataDetail.curve;
      let fen = this.dataDetail.score_type == 1 ? "分" : "%";
      let arr = [];
      for (var i = 0; i < this.dataDetailnumarr.length; i++) {
        var a = i + 1;
        arr.push(a);
      }
      myChart.setOption({
        xAxis: {
          type: "category",
          data: arr,
          axisLine: {
            show: false,
          },
          axisTick: {
            show: false,
          },
          axisLabel: {
            textStyle: {
              color: "#888",
              fontSize: "14",
              lineHeight: 21,
            },
          },
        },
        yAxis: {
          type: "value",
          // minInterval: 25,
          axisLine: {
            show: false,
          },
        },
        grid: {
          left: "0",
          right: "0",
          bottom: "25",
          top: "30",
        },
        series: [
          {
            label: {
              show: true,
              formatter: "{c} " + fen,
              position: "top",
              color: "#507FFF",
              fontSize: 14,
            },
            lineStyle: {
              color: "#507FFF",
            },
            itemStyle: {
              color: "#507FFF",
              width: 3,
            },
            data: this.dataDetailnumarr,
            type: "line",
            smooth: true,
            symbol: "circle",
            symbolSize: 10,
          },
        ],
      });
    },
  },
};
</script>

<style scoped lang="scss"></style>
