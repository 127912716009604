<template>
  <!-- 真题作战作答 -->
  <div class="questionsWrap">
    <header>
      <div class="headerWrap">
        <div class="headerTitle">
          <i class="el-icon-arrow-left" @click="goReturn()"></i>
          {{ Parper.title }}
        </div>
        <div class="headerWrapcz" v-if="dataDetail.is_answer_again == 1">
          <span class="headerWrapczsp" @click="againZd()">重新作答</span>
        </div>
      </div>
    </header>
    <main>
      <div class="mainWrap" id="mainWrap">
        <MyScore :dataDetail="dataDetail" />
        <ExaminationSituation
          :dataDetail="dataDetail"
          v-if="ExaminationSituationShow"
        />
        <DataReport ref="DataReport" />
        <MyAnswer :Parper="Parper" :total_num="dataDetail.total_num" />
        <div class="questionstishi">
            <dl v-if="Parper.note != ''">
              <dt class="fontSize18 lineH34">
                <i class="iconfont icon-uxbicon_matters fontSize24 lineH34"></i
                >注意事项
              </dt>
              <dd class="fontSize16 lineH32">
                {{ Parper.note }}
              </dd>
            </dl>
            <dl v-if="Parper.cover_tips != ''">
              <dt class="fontSize18 lineH34">
                <i class="iconfont icon-uxbicon_tips fontSize24 lineH34"></i
                >提示
              </dt>
              <dd class="fontSize16 lineH32">
                {{ Parper.cover_tips }}
              </dd>
            </dl>
        </div>
        <template v-if="bfIshow">
          <div v-for="(item1, index1) in question_infos" :key="index1">
            <!-- 段落 -->
            <Section :list="item1" v-if="item1.timu.length > 0"></Section>
            <div v-for="(item, index) in item1.timu" :key="index">
              <!-- 单选 -->
              <Radio
                class="itemWrap"
                v-if="item.is_stuff == 0 && item.rtype == 1"
                :list="item"
                :is_stuff="item.is_stuff"
              ></Radio>
              <!-- 多选 -->
              <Checkbox
                class="itemWrap"
                v-if="
                  (item.is_stuff == 0 && item.rtype == 2) ||
                  (item.is_stuff == 0 && item.rtype == 3)
                "
                :list="item"
                :is_stuff="item.is_stuff"
              ></Checkbox>
              <!-- 判断 -->
              <Estimate
                class="itemWrap"
                v-if="item.is_stuff == 0 && item.rtype == 5"
                :list="item"
                :is_stuff="item.is_stuff"
              ></Estimate>
              <!-- 论述 -->
              <Discuss
                class="itemWrap"
                v-if="item.is_stuff == 0 && item.rtype == 4"
                :list="item"
                :is_stuff="item.is_stuff"
              ></Discuss>
              <!-- 资料 -->
              <Materials
                class="itemWrap"
                v-if="item.is_stuff == undefined"
                :list="item"
              ></Materials>
            </div>
          </div>
        </template>
        <template v-else>
          <div v-for="(item, index) in question_infos" :key="index">
            <!-- 单选 -->
            <Radio
              class="itemWrap"
              v-if="item.is_stuff == 0 && item.rtype == 1"
              :list="item"
              :is_stuff="item.is_stuff"
            ></Radio>
            <!-- 多选 -->
            <Checkbox
              class="itemWrap"
              v-if="
                (item.is_stuff == 0 && item.rtype == 2) ||
                (item.is_stuff == 0 && item.rtype == 3)
              "
              :list="item"
              :is_stuff="item.is_stuff"
            ></Checkbox>
            <!-- 判断 -->
            <Estimate
              class="itemWrap"
              v-if="item.is_stuff == 0 && item.rtype == 5"
              :list="item"
              :is_stuff="item.is_stuff"
            ></Estimate>
            <!-- 论述 -->
            <Discuss
              class="itemWrap"
              v-if="item.is_stuff == 0 && item.rtype == 4"
              :list="item"
              :is_stuff="item.is_stuff"
            ></Discuss>
            <!-- 资料 -->
            <Materials
              class="itemWrap"
              v-if="item.is_stuff == undefined"
              :list="item"
            ></Materials>
          </div>
        </template>
      </div>
    </main>
    <footer>
      <AnswerSheet
        :answerTotal="this.question_infos"
        :type="bfIshow"
      ></AnswerSheet>
    </footer>
  </div>
</template>

<script>
import { answerinfo, paperinfo } from "./assets/api";
import MyAnswer from "./componentsRecite/MyAnswer"; //我的作答
import DataReport from "./componentsRecite/DataReport"; //数据报告
import ExaminationSituation from "./componentsRecite/ExaminationSituation"; //考试情况
import MyScore from "./componentsRecite/MyScore"; //我的成绩
import Section from "./componentsRecite/Section.vue"; //段落
import Radio from "./componentsRecite/Radio"; // 单选
import Checkbox from "./componentsRecite/Checkbox"; // 多选
import Estimate from "./componentsRecite/Estimate"; // 判断
import Discuss from "./componentsRecite/Discuss"; // 论述
import Materials from "./componentsRecite/Materials"; // 材料
import AnswerSheet from "./componentsRecite/com/AnswerSheet.vue"; //答题卡
export default {
  name: "paper_report",
  components: {
    Radio,
    Checkbox,
    Estimate,
    Discuss,
    Materials,
    AnswerSheet,
    Section,
    MyScore,
    ExaminationSituation,
    DataReport,
    MyAnswer,
  },
  data() {
    return {
      question_infos: [],
      questionData: {},
      num: "",
      seconds: 0,
      upTime: "",
      downTime: "",
      bfIshow: true,
      answerTotal: [],
      dataDetail: {},
      show_status: 0,
      Parper: {},
      ExaminationSituationShow: false,
      extar: {},
    };
  },
  mounted() {
    this.answerinfo();
    this.getpaperList();
  },
  methods: {
    goReturn() {
      this.$router.push({
        path:'/tiku'
      });
    },
    //数据处理ZQ
    dataProcessing(a, b, c, d) {
      a.map((item) => {
        if (item.rtype == 1 || item.rtype == 5 || item.rtype == 4) {
          this.$set(item, "radio", "");
          if (item.user_answer != "") {
            this.$set(item, "radio", item.user_answer);
          }
          if(item.rtype == 1 || item.rtype == 5){
            this.radioOptions(item)
          }
        } else if (item.rtype == 2 || item.rtype == 3) {
          this.$set(item, "radio", []);
          if (item.user_answer != "") {
            var ss = item.user_answer.split(",");
            this.$set(item, "radio", ss);
          }
          this.sub(item)
        }
        this.Question_list.push(item);
        this.$nextTick(() => {
          if (item.is_stuff > 0) {
            c.map((item1) => {
              if (item1.stuff_id == item.stuff_id) {
                if (!item1.list) {
                  this.$set(item1, "list", []);
                }
                this.$set(item1, "section_id", item.section_id);
                item1.list.push(item);
                b.push(item1);
                if (b.length > 0) {
                  b = this.unique(b);
                }
              }
            });
          } else {
            b.push(item);
          }
        });
        this.$nextTick(() => {
          if (d != undefined && d.length > 0) {
            d.map((itembf) => {
              this.$set(itembf, "timu", []);
              b.map((item) => {
                if (itembf.section_id == item.section_id) {
                  itembf.timu.push(item);
                }
              });
            });
            this.question_infos = d;
            this.bfIshow = true;
          } else {
            this.question_infos = b;
            this.bfIshow = false;
          }
        });
      });
    },
    //多选高亮处理
    sub(list1) {
      this.$set(list1, "subFlag", true);
      list1.result = list1.result.split(",");
      if (list1.radio.length > 0) {
        list1.radio.sort();
        let check = JSON.parse(JSON.stringify(list1.radio.sort()));
        if (list1.result.toString() == check.toString()) {
          this.$set(list1, "flag", "回答正确");
          list1.radio.map((item) => {
            list1.options.map((ite) => {
              if (item == ite.option_mark) {
                this.$set(ite, "className", "active");
              }
            });
          });
        } else {
          this.$set(list1, "flag", "回答错误");
          list1.options.map((ite) => {
            list1.radio.map(() => {
              if (
                      ite.is_correct == 1 &&
                      list1.radio.indexOf(ite.option_mark) != -1
              ) {
                this.$set(ite, "className", "active");
              } else if (
                      ite.is_correct == 1 &&
                      list1.radio.indexOf(ite.option_mark) == -1
              ) {
                this.$set(ite, "className", "right");
              } else if (list1.radio.indexOf(ite.option_mark) != -1) {
                this.$set(ite, "className", "error");
              } else {
                this.$set(ite, "className", "");
              }
            });
          });
        }
      } else {
        list1.options.map((ite) => {
          list1.result.map((item) => {
            if (item == ite.option_mark) {
              this.$set(ite, "className", "right");
            }
          });
        });
        this.$set(list1, "flag", "回答错误");
      }
    },
    //单选、判断高亮处理
    radioOptions(list1) {
      if (list1.user_answer != "") {
        list1.options.forEach((item) => {
          if (
                  item.option_mark != list1.result &&
                  list1.user_answer == item.option_mark
          ) {
            this.$set(item, "className", "error");
            this.$set(list1, "flag", "回答错误");
          } else if (
                  item.option_mark == list1.result &&
                  list1.user_answer == item.option_mark
          ) {
            this.$set(item, "className", "active");
            this.$set(list1, "flag", "回答正确");
          } else if (
                  item.option_mark == list1.result &&
                  list1.user_answer != item.option_mark
          ) {
            this.$set(item, "className", "right");
            this.$set(list1, "flag", "回答错误");
          }
        });
      }else{
        list1.options.forEach((item) => {
          if (
                item.option_mark == list1.result &&
                list1.user_answer != item.option_mark
          ) {
            this.$set(item, "className", "right");
            this.$set(list1, "flag", "回答错误");
          }
        });
      }
    },
    unique(arr) {
      for (var i = 0; i < arr.length; i++) {
        for (var j = i + 1; j < arr.length; j++) {
          if (arr[i].is_stuff == undefined) {
            if (arr[i].stuff_id == arr[j].stuff_id) {
              arr.splice(j, 1);
              j--;
            }
          }
        }
      }
      return arr;
    },
    //获取试题-试卷
    getpaperList() {
      let extar = {};
      let par = {
        extar: JSON.stringify(extar),
        show_status: this.show_status,
        paper_id: this.$route.query.paper_id,
        answer_id: this.$route.query.answer_id,
      };
      paperinfo(par).then((res) => {
        if (res.code == 0) {
          if (res.data.Question_list.length > 0) {
            let b = [];
            this.Question_list = res.data.Question_list;
            this.collect_id = res.data.collect_id;
            this.Parper = res.data.Paper;
            this.extar = res.data.Extar;
            this.dataProcessing(
              res.data.Question_list,
              b,
              res.data.Stuffinfos,
              res.data.Sectioninfos
            );
          } else {
            this.$message.error("暂无试题");
          }
        }
      });
    },
    answerinfo() {
      let data = {
        answer_id: this.$route.query.answer_id,
      };
      answerinfo(data).then((res) => {
        if (res.code == 0) {
          this.$refs.DataReport.$refs.GradeCurve.dataDetail =
            this.$refs.DataReport.dataDetail =
            this.dataDetail =
              res.data;
          if (res.data.sectioninfo.length > 0) {
            this.ExaminationSituationShow = true;
          } else {
            this.ExaminationSituationShow = false;
          }
          if (res.data.score) {
            this.$refs.DataReport.pointinfoShow = false;
          } else {
            this.$refs.DataReport.pointinfoShow = true;
          }
        }
      });
    },
    againZd() {
      if (this.extar.area_type == 2||this.extar.area_type == 5) {
        this.$router.push({
          path: "/pape_do",
          query: {
            answer_id: 0,
            paper_id: this.$route.query.paper_id,
            area_type: this.extar.area_type,
            special_id:this.$route.query.special_id,
          },
        });
      } else if (this.extar.area_type == 3) {
        this.$router.push({
          path: "/pape_do",
          query: {
            answer_id: 0,
            paper_id: this.$route.query.paper_id,
            area_type: this.extar.area_type,
            exam_id: this.extar.exam_id,
          },
        });
      }else if(this.extar.course_id){
        this.$router.push({
          path: "/pape_do",
          query: {
            answer_id: 0,
            area_type: 0,
            paper_id: this.$route.query.paper_id,
            course_id:  this.extar.course_id,
            lesson_id:this.extar.lesson_id
          },
        });
      }
    },
  },
  destroyed() {},
};
</script>

<style scoped lang="scss"></style>
