<template>
  <div class="ExaminationSituation" style="margin-bottom: 14px">
    <div class="Examtit clearfix">
      <h6><i class="iconfont icon-myzd"></i>我的作答</h6>
    </div>
    <div class="MyAnswerdiv clearfix">
      <ul class="MyAnswerdivfl clearfix">
        <li>正确</li>
        <li>错误</li>
        <li>漏选</li>
        <li>共计：{{ total_num }}道题</li>
        <li>
          限时：{{
            Parper.time_limit > 0 ? Parper.time_limit + "分钟" : "不限时"
          }}
        </li>
      </ul>
      <div class="MyAnswerdivfr fr">
        <el-checkbox v-model="checked" @change="lookCt()">只看错题</el-checkbox>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "MyAnswer",
  props: {
    Parper: Object,
    total_num: Number,
  },
  data() {
    return {
      checked: false,
    };
  },
  methods: {
    lookCt() {
      if (this.checked) {
        this.$parent.show_status = 1;
      } else {
        this.$parent.show_status = 0;
      }
      this.$parent.getpaperList();
    },
  },
};
</script>

<style scoped></style>
