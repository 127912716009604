<template>
  <div class="answerWrap">
    <div class="answerBtn" @click="upOrDown">
      <i class="leftIcon"
        ><img src="../../assets/images/icon_dp.png" alt=""
      /></i>
      答题卡
      <i class="el-icon-arrow-up rightIcon" v-if="!answerShow"></i>
      <i class="el-icon-arrow-down rightIcon" v-else></i>
    </div>
    <div class="answerList" v-if="answerShow">
      <ul class="answerListoul">
        <li>正确</li>
        <li>错误</li>
        <li>论述题</li>
        <li>未答</li>
      </ul>
      <div class="ansQue" v-if="type">
        <div v-for="(ite, inde) in answerTotal" :key="inde">
          <h2 class="fontSize16" v-if="ite.timu.length > 0">{{ ite.title }}</h2>
          <div
            style="display: inline"
            v-for="(item, index) in ite.timu"
            :key="index"
          >
            <template v-if="!item.list">
              <em @click="jump(item.question_seq)">
                {{ item.question_seq }}
                <span
                  class="iconfont"
                  :class="{
                    'icon-right right': item.right == 0,
                    'icon-wrong wrong': item.right == 1,
                    'icon-lunshu lunshu': item.right == 3,
                  }"
                ></span>
              </em>
              <i v-if="(index + 1) % 5 == 0 && (index + 1) % 15 != 0"></i>
            </template>
            <template v-if="item.list">
              <strong
                v-for="(item1, index1) in item.list"
                :key="index1"
                style="font-weight: normal"
              >
                <em @click="jump(item1.question_seq)">
                  {{ item1.question_seq }}
                  <span
                    class="iconfont"
                    :class="{
                      'icon-right right': item1.right == 0,
                      'icon-wrong wrong': item1.right == 1,
                      'icon-lunshu lunshu': item1.right == 3,
                    }"
                  ></span>
                </em>
                <i v-if="(index1 + 1) % 5 == 0 && (index1 + 1) % 15 != 0"></i>
              </strong>
            </template>
          </div>
        </div>
      </div>
      <div class="ansQue" v-else>
        <span v-for="(item, index) in answerTotal" :key="index">
          <template v-if="!item.list">
            <em @click="jump(item.question_seq)">
              {{ item.question_seq }}
              <span
                class="iconfont"
                :class="{
                  'icon-right right': item.right == 0,
                  'icon-wrong wrong': item.right == 1,
                  'icon-lunshu lunshu': item.right == 3,
                }"
              ></span>
            </em>
            <i v-if="(index + 1) % 5 == 0 && (index + 1) % 15 != 0"></i>
          </template>
          <template v-if="item.list">
            <strong
              v-for="(item1, index1) in item.list"
              :key="index1"
              style="font-weight: normal"
            >
              <em @click="jump(item1.question_seq)">
                {{ item1.question_seq }}
                <span
                  class="iconfont"
                  :class="{
                    'icon-right right': item1.right == 0,
                    'icon-wrong wrong': item1.right == 1,
                    'icon-lunshu lunshu': item1.right == 3,
                  }"
                ></span>
              </em>
              <i v-if="(index1 + 1) % 5 == 0 && (index1 + 1) % 15 != 0"></i>
            </strong>
          </template>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    answerTotal: Array,
    type: Boolean,
  },
  data() {
    return {
      answerShow: false,
    };
  },
  mounted() {},
  methods: {
    upOrDown() {
      this.answerShow = !this.answerShow;
    },
    jump(data) {
      let t = document.querySelector("#question" + data).offsetTop;
      window.scrollTo(0, t - 88);
    },
  },
};
</script>

<style scoped lang=""></style>
